import React from 'react';
import Link, { LinkProps } from 'next/link';
type IProps = React.PropsWithChildren<
  LinkProps & React.ComponentPropsWithRef<'a'> & React.ComponentPropsWithRef<'div'>
>;
const CustomLink: React.FC<IProps> = (props: IProps): React.JSX.Element => {
  const { href, children } = props;
  if (!href) return <div {...props}>{children}</div>;
  else if (typeof href === 'string')
    if (href.match('undefined')) {
      //handle undefined path
      return <div {...props}>{children}</div>;
    } else if (href.match('https:') || href.match('http:') || href.match('www')) {
      //handle external link
      return <a {...props}>{children}</a>;
    }

  return <Link {...props}>{children}</Link>;
};

export default CustomLink;
