'use client';
import { Dropdown, Modal, Button, MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import { localesSupport } from '@/configs/localeConfig';
import { getDomainFromCMS } from '@/utils';
import { useTranslations } from 'next-intl';
import { DownOutlined } from '@ant-design/icons';
import { JsonObject } from '@/types';

const LanguageSwitcher: React.FC = () => {
  const t = useTranslations();
  const getLanguageFrmCookie =
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('NEXT_LOCALE'))
      ?.split('=')[1] || localesSupport[0];
  const [modal, contextHolder] = Modal.useModal();
  const [currentLanguage, setCurrentLanguage] = useState(getLanguageFrmCookie);
  const [domainArray, setDomainArray] = useState<JsonObject[]>([]);
  const languagePlatform = [
    {
      id: localesSupport[0],
      label: t('locale.en'),
      project: 'doo_academy_global',
    },
    {
      id: localesSupport[1],
      label: t('locale.sc'),
      project: 'doo_academy_cn',
    },
  ];

  const items: MenuProps['items'] = languagePlatform.map((item) => {
    return { key: item.id, label: item.label, value: item.project };
  });

  const label = languagePlatform.find((item) => item.id === currentLanguage)?.label;

  const checkDomain = async () => {
    languagePlatform.forEach(async (item) => {
      const domain = await getDomainFromCMS(window.location.hostname, item.project);
      setDomainArray((prev) => [...prev, domain].flat());
    });
  };

  const switcherFunction = (toSwitchLanguage: string) => {
    if (currentLanguage === toSwitchLanguage) return;
    const currentLanguageObj = languagePlatform.find((item) => item.id === toSwitchLanguage);
    const domain = domainArray.find((item) => item.name === currentLanguageObj?.project)?.host;
    modal.confirm({
      title: `${t('switchWebsite.title')} ${currentLanguageObj?.label}`,
      content: t('switchWebsite.description'),
      icon: null,
      onOk() {
        setCurrentLanguage(toSwitchLanguage);
        if (domain) {
          window.location.href = `https://${domain}`;
        }
      },
    });
  };

  // only run once - get frm cms api
  useEffect(() => {
    checkDomain();
  }, []);

  return (
    <>
      <Dropdown
        menu={{
          items,
          onClick: (e) => {
            switcherFunction(e.key);
          },
        }}
      >
        <Button>
          {label} <DownOutlined />
        </Button>
      </Dropdown>
      <>{contextHolder}</>
    </>
  );
};

export default LanguageSwitcher;
