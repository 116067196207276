'use client';
import styles from './index.module.scss';
import { Button, Dropdown, Input, MenuProps, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useRouter } from 'next/navigation';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { logoutAction } from '@/lib/features/userSlice';
import CustomLink from '../Link';
import Image from 'next/image';
import { useTranslations } from 'next-intl';

const Header = () => {
  const router = useRouter();
  const { isLogin, user } = useAppSelector((state) => state.loginUser);
  const { headerTitle } = useAppSelector((state) => state.config);
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const items: MenuProps['items'] = [
    {
      label: <CustomLink href={'/my-center'}>{t('global.changePassword')}</CustomLink>,
      key: 'edit_password',
    },
    {
      label: t('global.logout'),
      key: 'login_out',
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'login_out') {
      message.success(t('global.logoutSuccess'));
      dispatch(logoutAction());
      router.push('/login');
    }
  };
  return (
    <div className={styles['app-header']}>
      <div className={`${styles['main-header']} }`}>
        <div className={styles['page-name']}>{headerTitle}</div>
        {isLogin && user && (
          <Button.Group className={styles['user-info']}>
            <Dropdown menu={{ items, onClick }} placement="bottomRight" overlayStyle={{ minWidth: 'unset' }}>
              <div className={styles['name']}>
                <Image
                  src={user?.avatar}
                  alt={`${user.nick_name}-profile-pic`}
                  width={32}
                  height={32}
                  priority={true}
                  style={{ marginRight: '12px', borderRadius: '50%' }}
                />
                <p>{user?.nick_name}</p>
                <DownOutlined style={{ fontSize: 12, marginLeft: 5, color: '#606266' }} />
              </div>
            </Dropdown>
          </Button.Group>
        )}
      </div>
    </div>
  );
};

export default Header;
