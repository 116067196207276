'use client';
import React, { useState, useRef, useEffect } from 'react';
import CustomLink from '../Link';
import styles from './index.module.scss';
import { MdArrowDropDown } from 'react-icons/md';
import { usePathname } from 'next/navigation';
import Image, { ImageProps } from 'next/image';
import monitor from '@public/profile/monitor.png';
import course from '@public/profile/course.png';
import folder from '@public/profile/folder.png';
import setting from '@public/profile/setting.png';
import chart from '@public/profile/chart.png';
import dropdown from '@public/profile/dropdown.png';
import book from '@public/profile/book.png';

export type MenuButtonProps = {
  title: string;
  icon: string;
  href?: string;
  sub?: {
    title: string;
    href: string;
    exact?: boolean;
  }[];
  defaultOpen?: boolean;
  target?: string;
};

const menuIcons: Record<string, ImageProps['src']> = {
  monitor: monitor,
  course: course,
  folder: folder,
  setting: setting,
  chart: chart,
  book: book,
};

const MenuButton: React.FC<MenuButtonProps> = ({ icon, title, href, sub = [], defaultOpen = false, target }) => {
  const [openAccordion, setOpenAccordion] = useState(defaultOpen);
  const pathname = usePathname();
  const contentRef = useRef<HTMLDivElement | null>(null);

  const toggle = () => {
    if (contentRef.current) {
      if (!openAccordion) {
        contentRef.current.style.height = 'auto';
      } else contentRef.current.style.height = 0 + 'px';
    }
    setOpenAccordion((prev) => !prev);
  };

  useEffect(() => {
    if (sub.map((item) => item.href).includes('/' + pathname.split('/')[1])) toggle();
  }, []);

  const renderIcon = (title: string) => {
    if (menuIcons[title]) {
      return <Image src={menuIcons[title]} alt={`${title} icon`} width={24} height={24} />;
    }
    return null;
  };

  return (
    <div className={styles['menu-item']}>
      {sub.length ? (
        <>
          <button className={styles['menu-button']} onClick={toggle}>
            <div className={styles['title']}>
              {renderIcon(icon)}
              {title}
            </div>
            <Image
              src={dropdown}
              alt="Dropdown icon"
              width={10}
              height={8}
              className={`${styles['arrow']} ${openAccordion ? styles['arrow-up'] : ''}`}
            />
          </button>
          <div ref={contentRef} className={styles['sub-menu']}>
            <div className={styles['sub-menu-panel']}>
              {sub.map(({ title, href, exact = false }) => {
                let selected = pathname.split('/')[1] === href.split('/')[1];
                if (exact) selected = pathname === href;
                return (
                  <CustomLink
                    key={title}
                    href={href}
                    className={`${styles['title']} ${selected ? styles['selected'] : ''}`}
                  >
                    {title}
                  </CustomLink>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <button className={styles['menu-button']}>
          <CustomLink
            key={title}
            href={href || ''}
            target={target}
            className={`${styles['title']} ${pathname === href ? styles['selected'] : ''}`}
          >
            {renderIcon(icon)}
            {title}
          </CustomLink>
        </button>
      )}
    </div>
  );
};

export default MenuButton;
