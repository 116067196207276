import(/* webpackMode: "eager" */ "/builds/p-brand/p-academy/academy-lecturer-react-18/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/p-brand/p-academy/academy-lecturer-react-18/public/logo.png");
;
import(/* webpackMode: "eager" */ "/builds/p-brand/p-academy/academy-lecturer-react-18/src/app/[locale]/(dashboard)/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/builds/p-brand/p-academy/academy-lecturer-react-18/src/components/FooterCopyright/index.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/p-brand/p-academy/academy-lecturer-react-18/src/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/p-brand/p-academy/academy-lecturer-react-18/src/components/LanguageSwitcher/index.tsx");
;
import(/* webpackMode: "eager" */ "/builds/p-brand/p-academy/academy-lecturer-react-18/src/components/LeftMenu/index.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/p-brand/p-academy/academy-lecturer-react-18/src/components/LeftMenu/MenuButton.tsx");
